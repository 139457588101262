import React from "react";
import "../CSS/Footer.css";
import { Link } from "react-router-dom";
// import { FaLinkedin } from "react-icons/fa";
// import { AiFillGoogleSquare } from "react-icons/ai";

function Footer() {
  return (
    <div className="footer-main">
      <div className="footer-top-flex">
        <div className=" footer-flex">
          <div className="footer-left">
            <h3 className="footer-title">About Global Impex</h3>
            <p className="footer-left-content">
              At Global Impex, we believe in maintaining Quality with Ethics. We
              value and trust our customers' value, and we attempt to uphold it
              by providing products of unrivalled quality, brought possible by
              sophisticated manufacturing facilities and rigorous checking and
              testing processes at all stages of production.
            </p>
          </div>
          <div className="footer-second">
            <h3 className="footer-title">Address</h3>
            <p>Global Impex</p>
            <p>19/55, 80 Feet Road</p>
            <p>Karur - 639 002</p>
          </div>
          <div className="footer-third">
            <h3 className="footer-title">Quick Links</h3>
            <ul>
              <li>
                <Link className="footer-link" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="/certifications">
                  Certifications
                </Link>
              </li>
              <li>
                <Link className="footer-link" to="/products">
                  Products
                </Link>
              </li>

              <li>
                <Link className="footer-link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-right">
            <h3 className="footer-title">Location</h3>
            <div className="map-footer">
              <iframe
                width="100%"
                height="auto"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Post%20Box%20No:52%20,%20%20No.46,%20New%20Street,%20%20Karur%20-%20639%20001+(Santh%20Exports)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className=" footer-bottom-flex">
        <div className="container footer-btm-flex">
          <div>
            <p className="footer-copyright">
              @2022 Global Impex. All Rights Reserved.
              <span>
                <a href="http://interdeccaan.in/" target="_blank">
                  Designed by Inter Deccaan Solutions
                </a>
              </span>
            </p>
          </div>
          {/* <div className="footer-social-icons">
            <FaLinkedin className="footer-icon" />
            <AiFillGoogleSquare className="footer-icon" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default Footer;
