import React from "react";
import "../CSS/Products.css";
import apron from "../Assets/Apron/6.jpg";
import cushion from "../Assets/Cushion/6.jpg";
import curtain from "../Assets/Curtain/6.jpg";
import throws from "../Assets/Throws/1.jpg";
import tablecloth from "../Assets/TableCloth/6.jpg";
import napkin from "../Assets/Napkins/4.jpg";
import runner from "../Assets/Runner/7.jpg";
import placemat from "../Assets/Placemat/9.webp";
import beachcloth from "../Assets/BeachTowel/1.jpg";
import laundrybag from "../Assets/LaundryBags/1.jpg";
import kitchentowel from "../Assets/KitchenTowel/1.jpg";
import product from "../Assets/product.jpg";

import { Link } from "react-router-dom";

function Products() {
  // window.scrollTo(0, 0);

  return (
    <div className="products-main-section">
      <div className="product-head">
        <img src={product}></img>
      </div>
      <div className="product-body-section">
        <h3 className="product-title">Product Categories</h3>
        <div className="product-list-flex">
          <Link to="/apron">
            <div className="product-main">
              <img src={apron} />
              <div className="product-caption">
                <h3>Apron</h3>
              </div>
            </div>
          </Link>
          <Link to="/cushion">
            <div className="product-main">
              <img src={cushion} />
              <div className="product-caption">
                <h3>Cushion</h3>
              </div>
            </div>
          </Link>
          <Link to="/curtain">
            <div className="product-main">
              <img src={curtain} />
              <div className="product-caption">
                <h3>Curtain</h3>
              </div>
            </div>
          </Link>
        </div>

        <div className="product-list-flex">
          <Link to="/throws">
            <div className="product-main">
              <img src={throws} />
              <div className="product-caption">
                <h3>Throws</h3>
              </div>
            </div>
          </Link>
          <Link to="/tablecloth">
            <div className="product-main">
              <img src={tablecloth} />
              <div className="product-caption">
                <h3>Table Cloths</h3>
              </div>
            </div>
          </Link>
          <Link to="/napkins">
            <div className="product-main">
              <img src={napkin} />
              <div className="product-caption">
                <h3>Napkins</h3>
              </div>
            </div>
          </Link>
        </div>

        <div className="product-list-flex">
          <Link to="/runner">
            <div className="product-main">
              <img src={runner} />
              <div className="product-caption">
                <h3>Runner</h3>
              </div>
            </div>
          </Link>
          <Link to="/placemat">
            <div className="product-main">
              <img src={placemat} />
              <div className="product-caption">
                <h3>Placemat</h3>
              </div>
            </div>
          </Link>
          <Link to="/beachtowel">
            <div className="product-main">
              <img src={beachcloth} />
              <div className="product-caption">
                <h3>Beach Towel</h3>
              </div>
            </div>
          </Link>
        </div>

        <div className="product-list-flex">
          <Link to="/laundrybags">
            <div className="product-main">
              <img src={laundrybag} />
              <div className="product-caption">
                <h3>Laundry Bags</h3>
              </div>
            </div>
          </Link>
          <Link to="/kitchentowel">
            <div className="product-main">
              <img src={kitchentowel} />
              <div className="product-caption">
                <h3>Kitchen Towel</h3>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Products;
