import React from "react";
import "../CSS/Certifications.css";
import cert1 from "../Assets/cert1.webp";
import cert2 from "../Assets/cert2.jpg";

function Certifications() {
  return (
    <div className="certificate-main">
      {/* Certification Section */}
      <div className="container cert-main">
        <h3 className="cert-main-title">Certifications</h3>
        <div className="cert-flex">
          <img src={cert1}></img>
          <img src={cert2}></img>
        </div>
      </div>
      <div className="container certi-flex">
        <div className="cert-left">
          <h3 className="cert-title">Social Compliance</h3>
          <p className="cert-content">
            Along with offering consumers safe products, we also seek additional
            moral manufacturing practises like promoting secure workplaces and
            using savagery. Recycled materials are used for wrapping when
            requested. We have been able to create a better future by means of
            the integration of natural processes.
          </p>
        </div>
        <div className="cert-right">
          <h3 className="cert-title">Quality Compliance</h3>
          <p className="cert-content">
            We ensure that sustainable practises will be used in the
            manufacturing of our home's textiles. No hazardous substances were
            used in the printing or dyeing of our products. We stick to
            incorporating ecologically secure practises since we are REACH
            compliant. Our credentials serve as proof that we are not only
            superior, but also ethically responsible. We are also adamant
            proponents of energy saving.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Certifications;
